import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

import { Search } from './routes/Search/Search';
import { Root } from './Root';
import { Home } from './routes/Home/Home';
import SearchContextProvider from './providers/SearchProvider';
import { View } from './routes/Search/View';
import { Privacy } from './routes/Privacy/Privacy';
import { Terms } from './routes/Terms/Terms';
import { Contact } from './routes/Contact/Contact';
import ResultsProvider from './providers/ResultsProvider';
import { Error } from './routes/Error/Error';
import { Basket } from './routes/Basket/Basket';
import BasketProvider from './providers/BasketProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <BasketProvider>
        <SearchContextProvider>
          <ResultsProvider>
            <section id='main-content'>
              <Routes>
                <Route path="/" element={<Root />}>
                  <Route path="privacy" element={<Privacy />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="basket" element={<Basket />} />
                  <Route path="apply" element={<Search />} />
                  <Route path="apply/:id" element={<View />} />
                  <Route path="apply/:id/:jobtitle" element={<View />} />
                  <Route path="search" element={<Search />} />
                  <Route path="search/:id" element={<View />} />
                  <Route path="search/:id/:jobtitle" element={<View />} />
                  <Route path="search/:id/:jobtitle/*" element={<View />} />
                  <Route path="*" element={<Error />} />
                  <Route index element={<Home />} />
                </Route>
              </Routes>
            </section>
          </ResultsProvider>
        </SearchContextProvider>
      </BasketProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
