import { createContext, useEffect, useState } from "react";
import { BaseUrl } from "../constants";
import { set } from "react-hook-form";

export const BasketContext = createContext();

const BasketProvider = (props) => {

    const basketStorageKey = 'bronte-jobs--basket'
    const [basket, setBasket] = useState({ RoleItems: [] });
    const [isBasketLoading, setIsBasketLoading] = useState(false);

    const getBasket = async () => {
        const basketId = getLocalBasket();
        setIsBasketLoading(true);

        if (!basketId) {
            await fetch(`${BaseUrl}api/basket`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: '',
            }).then(r => r.text()).then(code => {
                setLocalBasket(code);
                setIsBasketLoading(false);
                setBasket({ RoleItems: [] });
            })
        } else {
            await fetch(`${BaseUrl}api/basket/${basketId}`)
                .then(resp => resp.json())
                .then(data => {
                    setBasket(data);
                    setIsBasketLoading(false);
                })
        }

    }

    const addItem = async (id) => {
        const basketId = getLocalBasket();
        setIsBasketLoading(true);
        await fetch(`${BaseUrl}api/basket/update/${basketId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "jobId": id })
        }).then(resp => resp.json()).then(data => {
            setBasket(data);
            setIsBasketLoading(false);
        })
    }

    const isItemInBasket = (id) => {
        const item = basket.RoleItems.find(o => o._id === id);
        if (item) {
            return false
        }
        return true;
    }

    const removeItem = async (id) => {
        const basketId = getLocalBasket();
        setIsBasketLoading(true);
        await fetch(`${BaseUrl}api/basket/remove/${basketId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "jobId": id })
        }).then(resp => resp.json()).then(data => {
            setBasket(data);
            setIsBasketLoading(false);
        })
    }

    const clearBasket = async () => {

    }

    const getLocalBasket = () => {
        return sessionStorage.getItem(basketStorageKey);
    }

    const setLocalBasket = (basket) => {
        sessionStorage.setItem(basketStorageKey, basket);
    }

    useEffect(() => {
        async function load() {
            await getBasket();
        }

        load();
    }, [])

    return (
        <BasketContext.Provider value={{ basket, addItem, isItemInBasket, removeItem, isBasketLoading }}>
            {props.children}
        </BasketContext.Provider>
    );

}

export default BasketProvider;