
import './grid.css';

export function Grid({ header, body }) {
    return (
        <section className='grid'>
            <div className='grid__inner'>
                <div className='grid__content'>
                    <h2>{header}</h2>
                    <p dangerouslySetInnerHTML={
                        { __html: body }
                    }></p>
                </div>
                <div className='grid__image'></div>
            </div>
        </section>
    )
}
