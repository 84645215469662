import { useEffect } from "react";
import { Grid } from "../../components/Grid/Grid";
import { Hero } from "../../components/Hero/Hero";
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import { Carousel } from '../../components/Carousel/Carousel';
import { Helmet } from "react-helmet";
import { HomeHero } from "../../components/HomeHero/HomeHero";

export function Home() {

    const header = `Don't Delay, Find Yer Dream Job Today!`;
    const headerBody = `Find local job opportunities and take the next step in your career with Bronte Jobs. Applying is easy! We've simplified the process so you can quickly find and apply for roles that match your skills and experience. Ready to get started? Just click below to explore current openings.`;
    const headerLink = '/search';
    const headerLinkText = 'Have a Gander';

    const gridHeader = 'Find Your Dream Job in West Yorkshire with Bronte Jobs';
    const gridBody = ` Bronte Jobs connects talented individuals with top employers across the region.<br> We specialize in matching skills and aspirations with rewarding roles, providing dedicated support throughout your job search.
    <br><br>Start your career journey with us today!`;

    return (
        <>
            <Helmet>
                <title>Bronte Jobs: Find Jobs in West Yorkshire | Keighley and Surrounding Areas Job Board</title>
                <meta name="description" content="Find your next job in West Yorkshire with Bronte Jobs. Browse the latest vacancies, full-time and part-time, from top employers in Keighley, Bradford, and beyond.  Start your job search today!" />
            </Helmet>
            <HomeHero>
                <Carousel></Carousel>
            </HomeHero>
            <Grid header={gridHeader} body={gridBody}></Grid>
            <HeroNarrow mobileSimple={true} isSubHero={true} header={header} body={headerBody} link={headerLink} linkText={headerLinkText} align={'centre'}></HeroNarrow>
        </>
    )
}