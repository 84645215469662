import { Helmet } from "react-helmet"
import { HeroNarrow } from "../../components/HeroNarrow/HeroNarrow";
import { MyBasket } from "../../components/MyBasket/MyBasket";

export function Basket() {

    const headerText = 'Your basket';
    const headerBody = 'Add items to your basket so you view and compare them together'
    return (
        <>
           <Helmet>
                <title>Search for jobs in West Yorkshire | Bronte Jobs</title>
                <meta name="description" content={`Find the latest jobs in Bradford on Bronte Jobs. Browse current vacancies and apply online today`} />
            </Helmet>
            <HeroNarrow body={headerBody} hideButton={true} header={headerText} align={'centre'}></HeroNarrow>
            <MyBasket></MyBasket>
        </>
    )
}
