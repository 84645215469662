import { useContext } from "react"
import { BasketContext } from '../../providers/BasketProvider'
import { Link } from "react-router-dom";

export const MyBasket = () => {

    const { basket } = useContext(BasketContext);

    if (basket?.RoleItems.length === 0 || !basket) {
        return (
            <h2>Your Basket is currently Empty</h2>
        )
    } else {
        return (
            <ul>
                {
                    basket?.RoleItems.map(item => {
                        return (
                            <li key={item._id}>
                                <span>
                                {item.jobTitle}
                                </span>
                                <span>
                                    <button>Remove</button>
                                    <Link to={`/search/${item._id}/${item.jobTitle}`}>View</Link>
                                </span>
                                <hr/>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }
}