
import { Link } from 'react-router-dom';
import './HeroHome.scss';
import image from '/public/clouds.jpg';

export const HomeHero = ({ children }) => {

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    return (
        <div className='hero__home'>
            <div className='body__wrapper'>

                <div className='body__content'>
                    <h1>
                        Welcome to Bronte Jobs
                    </h1>
                    <p>
                        The best place in West Yorkshire for finding yer next job.  We're reet good at getting folk like you sorted with a proper job, whether it's full-time, part-time, or just a bit o' work on t' side.  Have a gander at what we've got - there's all sorts, so you're bound to find summat that takes yer fancy.
                    </p>
                    <Link to={'/search'} className='brand mobile__hide'>Have a Nosey</Link>
                </div>
            </div>
            <img src={image} alt="clouds" />

            <div className='carousel__wrapper'>
                {children}
            </div>
            <div className='overlay'></div>
        </div>
    )
}